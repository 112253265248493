<template>
  <div>
    <div class="d-flex justify-center" style="font-size: 8px">{{ userAgent }}</div>
    <div class="d-flex flex-wrap justify-space-around align-center" v-if="user">
      <div class="mt-4 mb-1">
        <v-alert
          v-if="user.landingMessage || landingMessage"
          border="left"
          colored-border
          type="error"
          elevation="5"
          icon="mdi-message-alert"
          max-width="344"
        >
          {{ landingMessage ? landingMessage : user.landingMessage }}
        </v-alert>

        <v-card rounded="xl" elevation="5" max-width="344">
          <ImageForCardWithTitle
            :image="user.image"
            :imageUrl="imageUrl"
            :title="`${firstName ? firstName : user.firstName}` + ' ' + `${lastName ? lastName : user.lastName}`"
            main-theme-color="teal"
            max-width="344"
          />
          <v-card-subtitle class="teal--text">{{ info ? info : user.info }}</v-card-subtitle>
          <v-card-subtitle class="teal--text">Телефон: {{ phone ? phone : user.phone }}</v-card-subtitle>
        </v-card>
      </div>
      <div style="max-width: 344px" class="my-5">
        <v-form @submit.prevent="changeHandler" style="min-width: 344px" class="my-5">
          <v-file-input
            v-model="imageFile"
            label="Выберите аватар"
            prepend-icon="mdi-image"
            color="teal"
            @change="setImageUrl"
          ></v-file-input>

          <v-text-field
            v-model="firstName"
            label="Имя"
            prepend-icon="mdi-account"
            color="teal"
          ></v-text-field>

          <v-text-field
            v-model="lastName"
            label="Фамилия"
            prepend-icon="mdi-account"
            color="teal"
          ></v-text-field>

          <v-textarea
            v-model="info"
            label="Введите информацию о себе"
            prepend-icon="mdi-information-slab-symbol"
            rows="2"
            color="teal"
          ></v-textarea>

          <v-textarea
            v-model="landingMessage"
            label="Сообщение для главной страницы"
            prepend-icon="mdi-message-alert"
            rows="2"
            color="teal"
            @click:append="deleteLandingMessage"
            append-icon="mdi-delete-forever"
          ></v-textarea>

          <v-btn
            color="teal"
            text
            @click="changeHandler"
          >
            Сохранить
          </v-btn>
        </v-form>
        <v-divider light></v-divider>
        <SwitchSMS style="min-width: 344px"/>
        <v-divider></v-divider>
        <ChangePassword style="min-width: 344px"/>
      </div>
    </div>
  </div>

</template>

<script>
import SwitchSMS from "@/components/SwitchSMS"
import ChangePassword from "@/components/ChangePassword"
import ImageForCardWithTitle from '@/components/ImageForCardWithTitle'

export default {
  name: "MasterSettings",
  components: {ImageForCardWithTitle, ChangePassword, SwitchSMS},
  data() {
    return {
      user: null,
      firstName: '',
      lastName: '',
      info: '',
      landingMessage: '',
      phone: '',
      imageFile: null,
      imageUrl: '',
      landingHint: '',
      userAgent: ''
    }
  },
  methods: {
    async loadUserInfo() {
      const id = this.$store.getters.ownerId
      const json = await this.authFetch('/user/load-user-info', {id})
      if (json.status === 200) {
        this.user = json.user
      }
    },

    async changeHandler() {
      const formData = new FormData()
      formData.append('firstName', this.firstName)
      formData.append('lastName', this.lastName)
      formData.append('info', this.info)
      formData.append('landingMessage', this.landingMessage)
      formData.append('image', this.imageFile)

      const json = await this.authFetchFormData('/user/change-user-info', formData, true)
      if (json.status === 200) {
        this.firstName = ''
        this.lastName = ''
        this.info = ''
        this.landingMessage = ''
        this.imageFile = null
        await this.loadUserInfo()
      }
    },

    async deleteLandingMessage() {
      if (!window.confirm('Вы уверены, что хотите удалить сообщение для лендинга?')) return
      const json = await this.authFetch('/user/delete-landing-message')
      if (json.status === 200) {
        this.landingMessage = ''
        await this.loadUserInfo()
      }
    },

    setImageUrl() {
      if (this.imageFile) this.imageUrl = URL.createObjectURL(this.imageFile)
      else this.imageUrl = ''
    },

  },
  async mounted() {
    await this.loadUserInfo()
    this.userAgent = navigator.userAgent
  }

}
</script>

<style scoped>

</style>
