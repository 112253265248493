<template>
  <v-list dense>
    <v-list-group
      color="teal"
      v-model="listActive"
      prepend-icon="mdi-lock-reset"
      no-action
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>Смена пароля</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-form v-model="valid" @submit.prevent="changePassword" ref="passwordForm">
        <v-list-item class="pl-5">
          <v-list-item-content>
            <v-list-item-title>Старый пароль</v-list-item-title>
            <v-text-field
              :type="oldPasswordType"
              :rules="oldPasswordRules"
              color="teal"
              required
              prepend-icon="mdi-lock-question"
              v-model="oldPassword"
              :append-icon="oldPasswordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="setOldPasswordType"
              label="Старый пароль"
            ></v-text-field>

            <v-list-item-title>Новый пароль</v-list-item-title>
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              color="teal"
              label="Новый пароль"
              required
              prepend-icon="mdi-lock"
              :append-icon="passwordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="setPasswordType"
              :type="passwordType"
            />

            <v-text-field
              v-model="passwordRepeat"
              color="teal"
              label="Новый пароль еще раз"
              required
              prepend-icon="mdi-lock-alert"
              :append-icon="passwordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="setPasswordType"
              :type="passwordType"
              :error-messages="passEqualError"
              @keyup="checkPassEqual"
            />

            <GoogleRecaptcha/>

            <v-btn
              @click="changePassword"
              text
              color="teal"
            >
              Сменить пароль
            </v-btn>


          </v-list-item-content>
        </v-list-item>
      </v-form>

    </v-list-group>
  </v-list>

</template>

<script>

import GoogleRecaptcha from "@/components/GoogleRecaptcha";
export default {
  name: "ChangePassword",
  components: {GoogleRecaptcha},
  data: () => ({
    oldPassword: '',
    oldPasswordRules: [v => !!v.length || "Заполните это поле"],
    password: '',
    passwordRepeat: '',
    passEqualError: "",
    passwordRules: [
      v => !!v.length || "Заполните это поле",
      v => v.length > 7 || "Длина пароля должна быть не менее 8 символов",
      v => /^(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]*$/.test(v) || 'Пароль должен содержать большие латинские буквы',
      v => /^(?=.*[a-z])[0-9a-zA-Z!@#$%^&*]*$/.test(v) || 'Пароль должен содержать маленькие латинские буквы',
      v => /^(?=.*[0-9])[0-9a-zA-Z!@#$%^&*]*$/.test(v) || 'Пароль должен содержать цифры',
      v => /^(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]*$/.test(v) || 'Пароль должен содержать специальные символы !@#$%^&*',
    ],
    passErrors: [],
    listActive: false,
    passwordType: 'password',
    oldPasswordType: 'password',
    valid: true
  }),
  methods: {
    async changePassword() {
      if (this.passEqualError) return

      this.$refs.passwordForm.validate()
      if (!this.valid) return

      //eslint-disable-next-line
      const recaptchaToken = grecaptcha.getResponse()
      if (!recaptchaToken) {
        this.$store.commit('setServerMessage', 'Пожалуйста, подтвердите, что Вы не робот')
        return
      }

      const json = await this.authFetch(
        '/auth/change-password-for-user',
        {oldPassword: this.oldPassword, password: this.password, recaptchaToken},
        true
      )

      if (json.status === 200) {
        this.$refs.passwordForm.reset()
      }
    },

    setPasswordType() {
      this.passwordType === "password" ? this.passwordType = "text" : this.passwordType = "password"
    },

    setOldPasswordType() {
      this.oldPasswordType === "password" ? this.oldPasswordType = "text" : this.oldPasswordType = "password"
    },

    checkPassEqual() {
      if (this.passwordRepeat !== this.password) {
        this.passEqualError = "Пароли не совпадают"
      } else {
        this.passEqualError = ""
      }
    },
  },
  async mounted() {
  }
}
</script>

