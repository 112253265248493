<template>
  <v-list dense>
    <v-list-group
      color="teal"
      v-model="listActive"
      prepend-icon="mdi-message-cog"
      no-action
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>СМС-уведомления</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item class="pl-5">
        <v-list-item-content>
          <v-list-item-title>Отправлять мастеру</v-list-item-title>
          <v-switch
            dense
            class="pl-3"
            v-model="toMaster"
            color="teal lighten-1"
            @change="switchHandler()"
            :label="`${toMaster ? 'ON' : 'OFF'}`"
          ></v-switch>
          <v-list-item-title>Отправлять клиентам</v-list-item-title>
          <v-switch
            dense
            class="pl-3"
            v-model="toClients"
            color="teal lighten-1"
            @change="switchHandler()"
            :label="`${toClients ? 'ON' : 'OFF'}`"
          ></v-switch>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>

</template>

<script>

export default {
  name: "SwitchSMS",
  data: () => ({
    toMaster: true,
    toClients: true,
    listActive: false,
  }),
  methods: {
    async switchHandler() {
      const toMaster = this.toMaster
      const toClients = this.toClients
      const json = await this.authFetch('/sms/change-messages-settings', {toMaster, toClients})
      if (json.status === 200) {
        await this.loadSettings()
      }
    },

    async loadSettings() {
      let json = await this.authFetch('/sms/get-messages-settings', {})
      if (json.status === 200) {
        this.toClients = json.settings.toClients
        this.toMaster = json.settings.toMaster
      }
    }
  },
  async mounted() {
    await this.loadSettings()
  }
}
</script>

